import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  company: Yup.string()
    .min(2, 'Company name must be 2 characters minimum')
    .required('Company is required'),
  zip: Yup.string()
    .min(2, 'Zip/Post code must be 2 characters minimum')
    .required('Zip/Post code is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),
  product: Yup.string()
    .min(2, 'Product must be 2 characters minimum')
    .required('Product is required'),
  productversion: Yup.string()
    .min(2, 'Product version must be 2 characters minimum')
    .required('Product version is required'),
  os: Yup.string()
    .min(2, 'Operating system must be 2 characters minimum')
    .required('OS is required'),
  osversion: Yup.string()
    .min(2, 'Operating system version must be 2 characters minimum')
    .required('OS version is required'),    
  message: Yup.string()
    .min(5, 'Message must be 5 characters at minimum')
    .required('Message is required'),
  consentsubscribe: Yup.boolean().oneOf([true], 'Your consent is required'),
  consentstore: Yup.boolean().oneOf([true], 'Your consent is required'),
})

const formUrl = 'https://script.google.com/macros/s/AKfycbw8sWhz2sHFe4TTAmlS1Uz3kknslsoBXjeRuB3THTHanlzQYkBbcF7Z0sVX6Mo77PzA/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class ContactMain extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                email: '',
                company: '',
                zip: '',
                country: '',             
                phone: '',
                product: '',
                productversion: '',
                os: '',
                osversion: '',
                deliverycompany: '',
                deliveryname: '',
                deliverycity: '',
                deliverystate: '',
                deliveryzip: '',
                deliverycountry: '',
                message: '',
                consentsubscribe: false,
                consentstore: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Name: values.name,
                  Email: values.email,
                  Company: values.company,
                  Zip: values.zip,
                  Country: values.country,                  
                  Phone: values.phone,
                  Product: values.product,
                  ProductVersion: values.productversion,
                  OS: values.os,
                  OSVersion: values.osversion,
                  DeliveryCompany: values.deliverycompany,
                  DeliveryName: values.deliveryname,
                  DeliveryCity: values.deliverycity,
                  DeliveryState: values.deliverystate,
                  DeliveryZip: values.deliveryzip,
                  DeliveryCountry: values.deliverycountry,
                  Message: values.message,
                  Consentsubscribe: values.consentsubscribe,
                  Consentstore: values.consentstore,
                }
                try {
                  const response = axios({
                      method: 'get',
                      url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&company=${encodeURIComponent(values.company)}&zip=${encodeURIComponent(values.zip)}&country=${encodeURIComponent(values.country)}&phone=${encodeURIComponent(values.phone)}&product=${encodeURIComponent(values.product)}&productversion=${encodeURIComponent(values.productversion)}&os=${encodeURIComponent(values.os)}&osversion=${encodeURIComponent(values.osversion)}&deliverycompany=${encodeURIComponent(values.deliverycompany)}&deliveryname=${encodeURIComponent(values.deliveryname)}&deliverycity=${encodeURIComponent(values.deliverycity)}&deliverystate=${encodeURIComponent(values.deliverystate)}&deliveryzip=${encodeURIComponent(values.deliveryzip)}&deliverycountry=${encodeURIComponent(values.deliverycountry)}&message=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.consentsubscribe)}&store=${encodeURIComponent(values.consentstore)}`
                    })
                      //console.log(response)
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                      messageConfirm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name"> Name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="name "name="name" aria-label="name" value={values.name} placeholder="Your full name" className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="name" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="email"> Email <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="email" name="email" aria-label="email" value={values.email} placeholder="Your email address" className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="company"> Company name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="company" name="company" aria-label="company" value={values.company} placeholder="Your company name" className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="company" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="zip"> Company zip/postcode <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="zip" name="zip" aria-label="text" value={values.zip} placeholder="Your zip/postcode" className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="zip" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country"> Country <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="country" name="country" aria-label="country" value={values.country} placeholder="Your country of residence" className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="country" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="phone"> Phone </label>
                        <Field type="tel" id="phone" name="phone" aria-label="phone" value={values.phone} placeholder="Your phone number" className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="phone" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="product"> Product <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="product" name="product" aria-label="product" value={values.product} placeholder="Software refresh for which product?" className={`form-control ${ touched.product && errors.product ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="product" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="productversion"> Product version <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="productversion" name="productversion" aria-label="productversion" value={values.productversion} placeholder="Your current product version & level, all 5 digits please" className={`form-control ${ touched.productversion && errors.productversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="productversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>               

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="os"> OS <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="os" name="os" aria-label="os" value={values.os} placeholder="Your operating system" className={`form-control ${ touched.os && errors.os ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="os" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="osversion"> OS version <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="osversion" name="osversion" aria-label="osversion" value={values.osversion} placeholder="Your operating system version" className={`form-control ${ touched.osversion && errors.osversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="osversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>     

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="deliverycompany"> Delivery company </label>
                        <Field type="text" id="deliverycompany" name="deliverycompany" aria-label="deliverycompany" value={values.deliverycompany} placeholder="Company this software refresh is for" className={`form-control ${ touched.deliverycompany && errors.deliverycompany ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverycompany" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="deliveryname"> Delivery contact </label>
                        <Field type="text" id="deliveryname" name="deliveryname" aria-label="deliveryname" value={values.deliveryname} placeholder="Contact name if different from above" className={`form-control ${ touched.deliveryname && errors.deliveryname ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliveryname" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliverycity"> Delivery city </label>
                        <Field type="text" id="deliverycity" name="deliverycity" aria-label="deliverycity" value={values.deliverycity} placeholder="The delivery city" className={`form-control ${ touched.deliverycity && errors.deliverycity ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverycity" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliverystate"> Delivery state/county </label>
                        <Field type="text" id="deliverystate" name="deliverystate" aria-label="deliverystate" value={values.deliverystate} placeholder="The delivery state/county" className={`form-control ${ touched.deliverystate && errors.deliverystate ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverystate" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>         

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliveryzip"> Delivery zip/postcode </label>
                        <Field type="text" id="deliveryzip" name="deliveryzip" aria-label="deliveryzip" value={values.deliveryzip} placeholder="The delivery zip/postcode" className={`form-control ${ touched.deliveryzip && errors.deliveryzip ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliveryzip" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="deliverycountry"> Delivery country </label>
                        <Field type="text" id="deliverycountry" name="deliverycountry" aria-label="deliverycountry" value={values.deliverycountry} placeholder="The delivery country" className={`form-control ${ touched.deliverycountry && errors.deliverycountry ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="deliverycountry" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>                                      

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">Message</label>
                        <Field type="textarea" component="textarea" name="message" aria-label="message" value={values.message} placeholder="Need to leave us a message?" className={`form-control ${ touched.message && errors.message ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="message" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                      You hereby consent to iET Solutions processing your email address provided in the form in order to inform you about iET Solutions products, services, news and events within the scope of a newsletter. The legal basis for the processing is Art. 6 para. 1 lit a) GDPR.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-2 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentsubscribe"
                          name="consentsubscribe"
                          aria-label="consentsubscribe"
                          className={`form-check-input ${ touched.consentsubscribe && errors.consentsubscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentsubscribe">
                        Yes, I would like to receive communications from iET Solutions. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="consentsubscribe" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                      You hereby consent to iET Solutions storing your data provided in this form on its servers and IT systems and processing it to respond to your request and provide the software update. The legal basis for the processing is Art. 6 para. 1 lit. a) GDPR.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentstore"
                          name="consentstore"
                          aria-label="consentstore"
                          className={`form-check-input ${ touched.consentstore && errors.consentstore ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentstore">
                          Yes, I agree. <span style={{ color: 'red' }}>*</span>
                        </label>
                        <ErrorMessage name="consentstore" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow>    

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                      Your consent given here is voluntary and can be revoked at any time and free of charge for the future. For more information on the storage and use of your personal data as well as your detailed rights and revocation options, please refer to our <Link to="/privacy-statement/" className="effect">privacy policy</Link>.
                      </div>
                    </MDBCol>
                  </MDBRow>                  

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>

     
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default ContactMain
